import React from "react";
import {
  ListHeader,
  ListHeading,
  Label,
  TextField,
  InputGroup,
  FormSectionWrapper,
  PrependText,
  TextFieldWrapper,
} from "../../common/styled-components";
import styled from "styled-components/macro";

export const SocialMediaFormSection = ({ values, onChange }) => {
  return (
    <>
      <ListHeader>
        <ListHeading>SOCIAL MEDIA DETAILS</ListHeading>
      </ListHeader>
      <FormSectionWrapper>
        <InputGroup>
          <Label htmlFor="facebookUrl">Facebook URL</Label>
          <TextField
            type="text"
            name="facebookUrl"
            value={values.facebookUrl || ""}
            onChange={onChange}
          />
        </InputGroup>
        <InputGroup>
          <Label htmlFor="instagramUrl">Instagram URL</Label>
          <TextField
            type="text"
            name="instagramUrl"
            value={values.instagramUrl || ""}
            onChange={onChange}
          />
        </InputGroup>
        {/* Commented below due to needing to parse in order to handle instagram usernames etc */}
        {/* <InputGroup>
          <Label htmlFor="facebookUrl">Facebook URL</Label>
          <InputWrapper>
            <PrependText>facebook.com/</PrependText>
            <TextFieldWrapper>
              <TextField
                showInline
                type="text"
                name="facebookUrl"
                value={values.facebookUrl || ""}
                onChange={onChange}
              />
            </TextFieldWrapper>
          </InputWrapper>
        </InputGroup>

        <InputGroup>
          <Label htmlFor="instagramUrl">Instagram Handle</Label>
          <InputWrapper>
            <PrependText>@</PrependText>
            <TextFieldWrapper>
              <TextField
                showInline
                type="text"
                name="instagramUrl"
                value={values.instagramUrl || ""}
                onChange={onChange}
              />
            </TextFieldWrapper>
          </InputWrapper>
        </InputGroup> */}

        <InputGroup>
          <Label htmlFor="websiteUrl">Website</Label>
          <TextField
            type="text"
            name="websiteUrl"
            value={values.websiteUrl || ""}
            onChange={onChange}
          />
        </InputGroup>
      </FormSectionWrapper>
    </>
  );
};

const InputWrapper = styled.div`
  width: 80%;
`;
