import React from "react";
import {
  ListHeader,
  ListHeading,
  Label,
  TextField,
  InputGroup,
  FormSectionWrapper
} from "../../common/styled-components";

export const DeliveryServicesFormSection = ({ values, onChange }) => {
  return (
    <>
      <ListHeader>
        <ListHeading>LINKED DELIVERY SERVICES</ListHeading>
      </ListHeader>
      <FormSectionWrapper>
        <InputGroup>
          <Label htmlFor="uberEatsUrl">UberEats URL</Label>
          <TextField
            type="text"
            name="uberEatsUrl"
            value={values.uberEatsUrl || ""}
            onChange={onChange}
          />
        </InputGroup>

        <InputGroup>
          <Label htmlFor="deliverooUrl">Deliveroo URL</Label>
          <TextField
            type="text"
            name="deliverooUrl"
            value={values.deliverooUrl || ""}
            onChange={onChange}
          />
        </InputGroup>

        <InputGroup>
          <Label htmlFor="menulogUrl">Menulog URL</Label>
          <TextField
            type="text"
            name="menulogUrl"
            value={values.menulogUrl || ""}
            onChange={onChange}
          />
        </InputGroup>

        <InputGroup>
          <Label htmlFor="heyYouUrl">HeyYou URL</Label>
          <TextField
            type="text"
            name="heyYouUrl"
            value={values.heyYouUrl || ""}
            onChange={onChange}
          />
        </InputGroup>
      </FormSectionWrapper>
    </>
  );
};
