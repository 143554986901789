import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { PrimaryButton, LoaderWrapper } from "../../common/styled-components";
import { DetailsFormSection } from "../FormSections/Details";
import { SocialMediaFormSection } from "../FormSections/SocialMedia";
import { DeliveryServicesFormSection } from "../FormSections/DeliveryServices";
import { AvailabilityFormSection } from "../FormSections/Availability";
import LoadingSpinner from "../LoadingSpinner";
import useForm from "../../common/useForm";
import axios from "axios";
import queryString from "query-string";
import Notifications, { notify } from "react-notify-toast";
import ClipLoader from "react-spinners/ClipLoader";

const DetailsForm = ({ onSubmitHandler, estData }) => {
  const [originalEntry, setOriginalEntry] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  const {
    values,
    setValues,
    handleChange,
    handleToggleChange,
    handleSubmit,
  } = useForm(async () => {
    setIsSaving(true);
    await onSubmitHandler(values);
    setIsSaving(false);
  });

  useEffect(() => {
    setOriginalEntry(estData);
    setValues(estData?.fields);
  }, []);

  if (!estData) {
    return (
      <LoaderWrapper>
        <LoadingSpinner />
      </LoaderWrapper>
    );
  } else {
    return (
      <form onSubmit={handleSubmit}>
        <FormBody>
          <Column>
            <FormSection>
              <DetailsFormSection values={values} onChange={handleChange} />
            </FormSection>
            <FormSection>
              <SocialMediaFormSection values={values} onChange={handleChange} />
            </FormSection>
            <FormSection>
              <AvailabilityFormSection
                values={values}
                onChange={handleToggleChange}
              />
            </FormSection>
          </Column>
          <Column>
            <FormSection>
              <DeliveryServicesFormSection
                values={values}
                onChange={handleChange}
              />
            </FormSection>
          </Column>
        </FormBody>
        <FormFooter>
          <PrimaryButton disabled={isSaving}>
            {!isSaving && "SAVE CHANGES"}
            {isSaving && <ClipLoader color="#fff" size={20} />}
          </PrimaryButton>
        </FormFooter>
      </form>
    );
  }
};

export default DetailsForm;

const FormFooter = styled.div`
  width: 100%;
  text-align: center;
`;
const Column = styled.div``;

const FormSection = styled.div``;

const FormBody = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  @media only screen and (min-device-width: 320px) and (max-width: 768px) {
    grid-gap: 10px;
    grid-template-columns: 1fr;
  }
`;

const BusinessName = styled.span`
  margin-left: 1em;
  font-weight: bold;
`;
