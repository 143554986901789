import React from "react";
import {
  ListHeader,
  ListHeading,
  FormSectionWrapper
} from "../../common/styled-components";
import styled from "styled-components/macro";

export const AvailabilityFormSection = ({ values, onChange }) => {
  return (
    <>
      <ListHeader>
        <ListHeading>AVAILABLE FOR</ListHeading>
      </ListHeader>
      <FormSectionWrapper>
        <CheckboxWrapper>
          DELIVERY
          <input
            type="checkbox"
            name="doesDelivery"
            checked={values.doesDelivery || false}
            onChange={onChange}
          />
          <span />
        </CheckboxWrapper>

        <CheckboxWrapper>
          PICKUP
          <input
            type="checkbox"
            name="doesPickup"
            checked={values.doesPickup || false}
            onChange={onChange}
          />
          <span />
        </CheckboxWrapper>
      </FormSectionWrapper>
    </>
  );
};

const CheckboxWrapper = styled.label`
  display: inline-block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 17px;
  margin-right: 20px;
  line-height: 25px;

  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  span {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
  }

  /* On mouse-over, add a grey background color */
  &:hover input ~ span {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  input:checked ~ span {
    background-color: #000;
    color: #fff;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  span:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  input:checked ~ span:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  span:after {
    left: 8px;
    top: 0;
    width: 10px;
    height: 20px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
