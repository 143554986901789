import React from "react";
import { WithContext as ReactTags } from "react-tag-input";
import "./TagInput.css";

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

export default class TagInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tags: props.value.map((val) => ({ id: val, text: val })),
      onChange: props.onChange,
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddition = this.handleAddition.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
  }

  convertTagsToArray() {
    const { tags } = this.state;
    const result = tags.map((tag) => {
      return tag.text;
    });
    return result;
  }

  handleDelete(i) {
    const { tags } = this.state;
    this.setState(
      {
        tags: tags.filter((tag, index) => index !== i),
      },
      () => {
        this.state.onChange(this.convertTagsToArray());
      }
    );
  }

  handleAddition(tag) {
    this.setState(
      (state) => ({ tags: [...state.tags, tag] }),
      () => {
        this.state.onChange(this.convertTagsToArray());
      }
    );
  }

  handleDrag(tag, currPos, newPos) {
    const tags = [...this.state.tags];
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    this.setState({ tags: newTags }, () => {
      this.state.onChange(this.convertTagsToArray());
    });
  }

  render() {
    const { tags, suggestions } = this.state;
    return (
      <div>
        <ReactTags
          tags={tags}
          placeholder={"Press enter to add a new tag"}
          handleDelete={this.handleDelete}
          handleAddition={this.handleAddition}
          handleDrag={this.handleDrag}
          delimiters={delimiters}
          autofocus={false}
          required
        />
      </div>
    );
  }
}
