import {
  useState
} from "react";

const useForm = callback => {
  const [values, setValues] = useState({});

  const handleSubmit = event => {
    if (event) event.preventDefault();
    callback();
  };

  const handleChange = event => {
    event.persist();
    setValues(values => ({
      ...values,
      [event.target.name]: event.target.value
    }));
  };

  // THIS IS ONLY TO BE USED WHEN THE EVENT OBJECT IS NOT AVAILABLE!!!
  const handleProgrammaticChange = (field, value) => {
    setValues(values => ({
      ...values,
      [field]: value
    }))
  }

  const handleToggleChange = event => {
    event.persist();
    setValues(values => ({
      ...values,
      [event.target.name]: event.target.checked
    }));
  };

  return {
    handleChange,
    handleToggleChange,
    handleSubmit,
    handleProgrammaticChange,
    values,
    setValues
  };
};

export default useForm;