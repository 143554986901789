import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import {
  Body,
  Container,
  Title,
  Subtext,
  PrimaryButton,
  LoaderWrapper,
} from "../common/styled-components";
import styled from "styled-components/macro";
import { DetailsFormSection } from "../components/FormSections/Details";
import { SocialMediaFormSection } from "../components/FormSections/SocialMedia";
import { DeliveryServicesFormSection } from "../components/FormSections/DeliveryServices";
import { AvailabilityFormSection } from "../components/FormSections/Availability";
import useForm from "../common/useForm";
import axios from "axios";
import queryString from "query-string";
import Notifications, { notify } from "react-notify-toast";
import ClipLoader from "react-spinners/ClipLoader";
import LoadingSpinner from "../components/LoadingSpinner";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import DetailsForm from "../components/Portal/DetailsForm";
import PostList from "../components/Portal/PostsList";

export default ({ data, location }) => {
  const [originalEntry, setOriginalEntry] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [posts, setPosts] = useState([]);

  const onEstDetailsSubmit = async (updatedValues) => {
    // setIsSaving(true);
    const updatedEntry = Object.assign({}, originalEntry, {
      ...originalEntry.data,
      fields: updatedValues,
    });

    try {
      const res = await axios.post(
        `/.netlify/functions/updateEstablishment`,
        updatedEntry
      );

      if (res.status === 200) {
        notify.show("Saved", "success");
      } else {
        notify.show(
          "Sorry, looks like something went wrong. Please try again.",
          "error"
        );
      }
    } catch (err) {
      console.error(err);
      notify.show(
        "Sorry, looks like something went wrong. Please try again.",
        "error"
      );
    } finally {
      // setIsSaving(false);
    }
  };

  const fetchEstablishmentDetails = async () => {
    try {
      const params = queryString.parse(location.search);
      const result = await axios.get(
        `/.netlify/functions/getEstablishment?query=${params.id}`
      );
      setOriginalEntry(result.data);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchPosts = async () => {
    try {
      setIsLoading(true);
      const params = queryString.parse(location.search);
      const result = await axios.get(
        `/.netlify/functions/establishmentPosts?businessId=${params.id}`
      );
      setPosts(result.data.results);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const runEffect = async () => {
      setIsLoading(true);
      await fetchEstablishmentDetails();
      await fetchPosts();
      setIsLoading(false);
    };
    runEffect();
  }, []);

  return (
    <Layout>
      <Notifications options={{ top: "10px", fontSize: "2rem" }} />
      <Body>
        <Container>
          <Header>
            <Title>Your establishment portal</Title>
            <Subtext>
              Welcome to your establishment portal. This is where you can edit
              your business details for your takeitaway page.
            </Subtext>
            <Subtext>
              You are currently editing for:
              <BusinessName>
                {originalEntry && originalEntry.fields
                  ? originalEntry.fields.businessName
                  : ""}
              </BusinessName>
            </Subtext>
            <CurrentEditingTitle></CurrentEditingTitle>
          </Header>
          {isLoading && (
            <LoaderWrapper>
              <LoadingSpinner />
            </LoaderWrapper>
          )}
          {!isLoading && (
            <Tabs>
              <TabList style={{ fontSize: "2rem" }}>
                <Tab>Posts</Tab>
                <Tab>Establishment Details</Tab>
              </TabList>
              <TabPanel>
                <PostList
                  businessName={originalEntry.fields.businessName}
                  businessId={originalEntry.sys.id}
                  posts={posts}
                  postUpdateCallback={fetchPosts}
                  notificationCallback={notify}
                />
              </TabPanel>
              <TabPanel>
                <DetailsForm
                  onSubmitHandler={onEstDetailsSubmit}
                  estData={originalEntry}
                />
              </TabPanel>
            </Tabs>
          )}
        </Container>
      </Body>
    </Layout>
  );
};

const Header = styled.div`
  margin-bottom: 35px;
`;
const CurrentEditingTitle = styled.div``;
const FormBody = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  @media only screen and (min-device-width: 320px) and (max-width: 768px) {
    grid-gap: 10px;
    grid-template-columns: 1fr;
  }
`;
const FormFooter = styled.div`
  width: 100%;
  text-align: center;
`;
const Column = styled.div``;
const FormSection = styled.div``;
const BusinessName = styled.span`
  margin-left: 1em;
  font-weight: bold;
`;
