import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import useForm from "../../common/useForm";
import LoadingSpinner from "../LoadingSpinner";
import { PrimaryButton, LoaderWrapper } from "../../common/styled-components";
import NewsPost from "../NewsPost";
import TagInput from "./TagInput/TagInput";
import { encode } from "base64-arraybuffer";
import DropZone from "../DropZone";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import moment from "moment";

import {
  Label,
  TextField,
  InputGroup,
  FormSectionWrapper
} from "../../common/styled-components";

const PostCreationForm = ({
  post,
  businessName,
  businessId,
  index,
  notificationCallback,
  postUpdateCallback
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [creatingPost, setCreatingPost] = useState(false);

  const {
    values,
    setValues,
    handleChange,
    handleProgrammaticChange,
    handleSubmit
  } = useForm(async () => {
    try {
      setIsSaving(true);

      if (!values.postImage.fields.file.url) {
        notificationCallback.show(
          `Please upload a post image before saving your post`,
          "error"
        );
        return;
      }

      if (values.areasOfAvailability.length === 0) {
        notificationCallback.show(
          `Please include at least one value in Areas of Availability.`,
          "error"
        );
        return;
      }

      const result = await axios.post(`/.netlify/functions/createPost`, {
        sys: {},
        fields: values
      });

      if (result.status === 200) {
        notificationCallback.show("Saved", "success");
        setValues(createEmptyPost().fields);
        postUpdateCallback();
      } else {
        notificationCallback.show(
          `Oops! Something went wrong: ${result.msg}`,
          "error"
        );
      }
    } catch (error) {
      notificationCallback.show(
        `Oops! Something went wrong. Please refresh the page`,
        "error"
      );
    } finally {
      setIsSaving(false);
    }
  });

  const handleDrops = acceptedFiles => {
    acceptedFiles.forEach(file => {
      const { name, type } = file;
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        // get an ArrayBuffer version of image
        const binaryStr = reader.result;

        // in a dirty way, encode ArrayBuffer as base64 string, then rewrite the postImage url
        // in the original contentful post object, with this base64 string.
        // a function in the newsPost component will parse the the string to handle both
        // a proper image url and a base64 string image. This is how the preview editing works.
        handleProgrammaticChange("postImage", {
          fields: {
            file: {
              url: encode(binaryStr),
              fileName: name,
              contentType: type
            }
          }
        });
      };
      reader.readAsArrayBuffer(file);
    });
  };

  const createEmptyPost = () => {
    return {
      fields: {
        postImage: { fields: { file: { url: "" } } },
        businessName: businessName,
        title: "",
        description: "",
        areasOfAvailability: [],
        majorCity: "",
        externalPostLink: "",
        businessId: { sys: { id: businessId } }
      }
    };
  };

  const todaysDate = () => {
    return moment().format("DD MMM YYYY");
  };

  useEffect(() => {
    if (!post) {
      setValues(createEmptyPost().fields);
    } else {
      setValues(post.fields);
    }
    setIsLoading(false);
  }, []);

  if (isLoading || Object.keys(values).length === 0) {
    return (
      <LoaderWrapper>
        <LoadingSpinner />
      </LoaderWrapper>
    );
  } else {
    return (
      <>
        <CreateNewPostButton onClick={() => setCreatingPost(!creatingPost)}>
          Create new post
        </CreateNewPostButton>
        {creatingPost && (
          <PostEditor>
            <EditorHeading>Preview of Post</EditorHeading>
            <NewsPost
              key={index}
              postId={""}
              businessName={values.businessName}
              title={values.title}
              timestamp={todaysDate()}
              description={values.description}
              postImage={values.postImage.fields.file.url}
              areasOfAvailability={values.areasOfAvailability}
              externalPostLinkUrl={values.externalPostLink}
              businessId={values.businessId.sys.id}
              editMode={true}></NewsPost>
            <form onSubmit={handleSubmit} key={index}>
              <FormSectionWrapper>
                <InputGroup>
                  <Label>Post Image (required)</Label>
                  <DropZone onDropCallback={handleDrops} />
                </InputGroup>

                <InputGroup>
                  <Label>Post Title (required)</Label>
                  <TextField
                    required
                    type="text"
                    name="title"
                    value={values.title || ""}
                    onChange={handleChange}
                    minLength={20}
                    maxLength={250}
                  />
                </InputGroup>

                <InputGroup>
                  <Label>Description (required)</Label>
                  <TextField
                    required
                    type="text"
                    name="description"
                    value={values.description || ""}
                    onChange={handleChange}
                    minLength={50}
                    maxLength={300}
                  />
                </InputGroup>

                <InputGroup>
                  <Label>Closest Major City (required)</Label>
                  <TextField
                    required
                    type="text"
                    name="majorCity"
                    value={values.majorCity || ""}
                    onChange={handleChange}
                  />
                </InputGroup>
                <InputGroup>
                  <Label>
                    Areas of availability (put TBC if uncertain) (required)
                  </Label>
                  <TagInput
                    required
                    value={values.areasOfAvailability || ""}
                    onChange={value =>
                      handleProgrammaticChange("areasOfAvailability", value)
                    }
                  />
                </InputGroup>

                <InputGroup>
                  <Label>Link to an external post</Label>
                  <TextField
                    type="text"
                    name="externalPostLink"
                    value={values.externalPostLink || ""}
                    onChange={handleChange}
                  />
                </InputGroup>

                <FormFooter>
                  <PrimaryButton disabled={isSaving}>
                    {!isSaving && "CREATE POST"}
                    {isSaving && <ClipLoader color="#fff" size={20} />}
                  </PrimaryButton>
                </FormFooter>
              </FormSectionWrapper>
            </form>
          </PostEditor>
        )}
      </>
    );
  }
};

export default PostCreationForm;

const CreateNewPostButton = styled.button`
  width: 100%;
  padding: 20px;
  border: 2px dashed black;
  border-radius: 10px;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 30px;
`;

const EditorHeading = styled.div`
  font-weight: bold;
  font-size: 2rem;
  margin-bottom: 20px;
`;

const PostEditor = styled.div`
  border: 2px solid black;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 30px;
`;

const FormFooter = styled.div`
  width: 100%;
  text-align: center;
`;
