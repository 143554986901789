import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components/macro";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "145px",
};

const DropZone = ({ onDropCallback }) => {
  const [files, setFiles] = useState([]);
  const [fileWasRejected, setFileWasRejected] = useState(false);
  const { getRootProps, getInputProps } = useDropzone({
    maxSize: 3145728,
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
    onDropRejected: () => {
      setFileWasRejected(true);
      setTimeout(() => setFileWasRejected(false), 3000);
    },
  });

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img src={file.preview} style={img} />
      </div>
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach((file) => URL.revokeObjectURL(file.preview));
    if (files) {
      onDropCallback(files);
    }
  }, [files]);

  return (
    <DropInputWrapper>
      <InputArea {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
      </InputArea>
      <RejectionError active={fileWasRejected}>
        Double check that your file is &#60; 3MB and an IMAGE (e.g. png, jpg, or
        svg)
      </RejectionError>
      <Thumbnails style={thumbsContainer}>{thumbs}</Thumbnails>
    </DropInputWrapper>
  );
};
export default DropZone;
const DropInputWrapper = styled.section`
  margin-top: 5px;
  min-height: 50px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border: 2px dashed black;
  border-radius: 10px;
  font-size: 1.6rem;
  text-align: center;
`;

const RejectionError = styled.div`
  width: 100%;
  padding: 5px;
  color: white;
  background-color: #f44336;
  border-radius: 5px;
  opacity: ${(props) => (props.active ? 1 : 0)};
  transition: 0.2s;
`;

const InputArea = styled.div`
  width: 100%;
`;

const Thumbnails = styled.div`
  display: block;
`;
