import React from "react";
import {
  ListHeader,
  ListHeading,
  Label,
  TextField,
  InputGroup,
  FormSectionWrapper,
} from "../../common/styled-components";

export const DetailsFormSection = ({ values, onChange }) => {
  return (
    <>
      <ListHeader>
        <ListHeading>ESTABLISHMENT DETAILS</ListHeading>
      </ListHeader>
      <FormSectionWrapper>
        <InputGroup>
          <Label htmlFor="businessName">Establishment name</Label>
          <TextField
            type="text"
            name="businessName"
            value={values.businessName || ""}
            onChange={onChange}
          />
        </InputGroup>

        <InputGroup>
          <Label htmlFor="type">Type of establishment</Label>
          <TextField
            halfWidth
            type="text"
            name="type"
            value={values.type || ""}
            onChange={onChange}
          />
        </InputGroup>

        <InputGroup>
          <Label htmlFor="address">Address</Label>
          <TextField
            type="text"
            name="address"
            value={values.address || ""}
            onChange={onChange}
          />
        </InputGroup>

        <InputGroup>
          <Label htmlFor="contactNumber">Phone number</Label>
          <TextField
            halfWidth
            type="text"
            name="contactNumber"
            value={values.contactNumber || ""}
            onChange={onChange}
          />
        </InputGroup>
        <InputGroup>
          <Label htmlFor="menuUrl">Menu URL</Label>
          <TextField
            type="text"
            name="websiteUrl"
            value={values.menuUrl || ""}
            onChange={onChange}
          />
        </InputGroup>
      </FormSectionWrapper>
    </>
  );
};
