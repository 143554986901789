import React, { useState } from "react";
import styled from "styled-components/macro";
import { Container } from "../../common/styled-components";

import PostForm from "./PostForm";
import PostCreationForm from "./PostCreationForm";

const PostsList = ({
  posts,
  businessName,
  businessId,
  notificationCallback,
  postUpdateCallback,
}) => {
  return (
    <Container style={{ display: "flex", flexWrap: "wrap" }}>
      <PostsWrapper>
        <PostCreationForm
          businessName={businessName}
          businessId={businessId}
          notificationCallback={notificationCallback}
          postUpdateCallback={postUpdateCallback}
        />
        <PostList>
          {posts.map((post, index) => {
            return (
              <PostForm
                post={post}
                key={index}
                postUpdateCallback={postUpdateCallback}
                notificationCallback={notificationCallback}
                postUpdateCallback={postUpdateCallback}></PostForm>
            );
          })}
        </PostList>
      </PostsWrapper>
    </Container>
  );
};

export default PostsList;

const PostsWrapper = styled.div`
  flex-basis: 100%;
`;

const PostList = styled.div`
  margin-bottom: 20px;
`;
