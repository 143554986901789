import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import useForm from "../../common/useForm";
import LoadingSpinner from "../LoadingSpinner";
import { PrimaryButton, LoaderWrapper } from "../../common/styled-components";
import NewsPost from "../NewsPost";
import TagInput from "./TagInput/TagInput";
import { encode } from "base64-arraybuffer";
import DropZone from "../DropZone";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import moment from "moment";

import {
  Label,
  TextField,
  InputGroup,
  FormSectionWrapper
} from "../../common/styled-components";

const PostForm = ({
  post,
  index,
  notificationCallback,
  postUpdateCallback
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);

  const {
    values,
    setValues,
    handleChange,
    handleProgrammaticChange,
    handleToggleChange,
    handleSubmit
  } = useForm(async () => {
    try {
      setIsSaving(true);

      if (!values.postImage.fields.file.url) {
        notificationCallback.show(
          `Please upload a post image before saving your post`,
          "error"
        );
        return;
      }

      if (values.areasOfAvailability.length === 0) {
        notificationCallback.show(
          `Please include at least one value in Areas of Availability.`,
          "error"
        );
        return;
      }

      const result = await axios.post(`/.netlify/functions/updatePost`, {
        sys: post.sys,
        fields: values
      });

      if (result.status === 200) {
        notificationCallback.show("Saved", "success");
      } else {
        notificationCallback.show(
          `Oops! Something went wrong: ${result.msg}`,
          "error"
        );
      }
    } catch (error) {
      notificationCallback.show(
        `Oops! Something went wrong. Please refresh the page`,
        "error"
      );
    } finally {
      setIsSaving(false);
    }
  });

  const toggleEditor = () => setIsEditing(!isEditing);

  const handleDrops = acceptedFiles => {
    acceptedFiles.forEach(file => {
      const { name, type } = file;
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        // get an ArrayBuffer version of image
        const binaryStr = reader.result;

        // in a dirty way, encode ArrayBuffer as base64 string, then rewrite the postImage url
        // in the original contentful post object, with this base64 string.
        // a function in the newsPost component will parse the the string to handle both
        // a proper image url and a base64 string image. This is how the preview editing works.
        handleProgrammaticChange("postImage", {
          fields: {
            file: {
              url: encode(binaryStr),
              fileName: name,
              contentType: type
            }
          }
        });
      };
      reader.readAsArrayBuffer(file);
    });
  };

  const deletePost = async title => {
    if (confirm(`Do you want to delete the post with title: "${title}"?`)) {
      const result = await axios.post(`/.netlify/functions/deletePost`, {
        businessId: post.fields.businessId.sys.id,
        entryId: post.sys.id
      });

      if (result.status === 200) {
        notificationCallback.show("Post deleted!", "success");
        postUpdateCallback();
      } else {
        notificationCallback.show(
          "Oops! Something went wrong. Please refresh the page",
          "error"
        );
      }
    }
  };

  useEffect(() => {
    setValues(post.fields);
    setIsLoading(false);
  }, []);

  if (isLoading || Object.keys(values).length === 0) {
    return (
      <LoaderWrapper>
        <LoadingSpinner />
      </LoaderWrapper>
    );
  } else {
    return (
      <PostEditor>
        <EditButton onClick={toggleEditor}>
          {isEditing ? "CLOSE EDITOR" : "EDIT POST"}
        </EditButton>
        <DeleteButton onClick={() => deletePost(values.title)}>
          DELETE POST
        </DeleteButton>
        <NewsPost
          key={index}
          postId={post.sys.id}
          businessName={values.businessName}
          title={values.title}
          timestamp={moment(post.sys.createdAt).format("DD MMM YYYY")}
          description={values.description}
          postImage={values.postImage.fields.file.url}
          areasOfAvailability={values.areasOfAvailability}
          externalPostLinkUrl={values.externalPostLink}
          businessId={values.businessId.sys.id}
          editMode={true}></NewsPost>
        {isEditing && (
          <form onSubmit={handleSubmit} key={index}>
            <FormSectionWrapper>
              <InputGroup>
                <Label>Post Image</Label>
                <DropZone onDropCallback={handleDrops} />
              </InputGroup>

              <InputGroup>
                <Label>Post Title (required)</Label>
                <TextField
                  required
                  type="text"
                  name="title"
                  value={values.title || ""}
                  onChange={handleChange}
                  minLength={20}
                  maxLength={250}
                />
              </InputGroup>

              <InputGroup>
                <Label>Description (required)</Label>
                <TextField
                  required
                  type="text"
                  name="description"
                  value={values.description || ""}
                  onChange={handleChange}
                  minLength={50}
                  maxLength={300}
                />
              </InputGroup>

              <InputGroup>
                <Label>Closest Major City (required)</Label>
                <TextField
                  required
                  type="text"
                  name="majorCity"
                  value={values.majorCity || ""}
                  onChange={handleChange}
                />
              </InputGroup>
              <InputGroup>
                <Label>
                  Areas of availability (put TBC if uncertain) (required)
                </Label>
                <TagInput
                  required
                  value={values.areasOfAvailability || ""}
                  onChange={value =>
                    handleProgrammaticChange("areasOfAvailability", value)
                  }
                />
              </InputGroup>

              <InputGroup>
                <Label>Link to an external post</Label>
                <TextField
                  type="text"
                  name="externalPostLink"
                  value={values.externalPostLink || ""}
                  onChange={handleChange}
                />
              </InputGroup>

              <FormFooter>
                <PrimaryButton>
                  {!isSaving && "SAVE CHANGES"}
                  {isSaving && <ClipLoader color="#fff" size={20} />}
                </PrimaryButton>
              </FormFooter>
            </FormSectionWrapper>
          </form>
        )}
      </PostEditor>
    );
  }
};

export default PostForm;

const PostEditor = styled.div`
  border: 2px solid black;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
`;

const FormFooter = styled.div`
  width: 100%;
  text-align: center;
`;

const EditButton = styled(PrimaryButton)`
  float: right;
  margin-left: 10px;
`;

const DeleteButton = styled(EditButton)``;
